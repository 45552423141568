import React           from 'react';
import { Link }        from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import Page from '../components/Page';
import SEO  from '../components/atom/SEO';

export const IndexPage = (): JSX.Element => (
     <Page className="page-home">
          <SEO title="Home" />
          <h1>Hi people</h1>
          <p>Welcome to your new Gatsby site.</p>
          <p>Now go build something great.</p>
          <StaticImage
               src="../images/gatsby-astronaut.png"
               width={ 300 }
               quality={ 95 }
               formats={ [ 'auto', 'webp', 'avif' ] }
               alt="A Gatsby astronaut"
               style={ { marginBottom: `1.45rem` } }
          />
          <p>
               <Link to="/page-2/">Go to page 2</Link> <br />
               <Link to="/using-typescript/">Go to &ldquo;Using TypeScript&rdquo;</Link>
          </p>
     </Page>
);

export default IndexPage;
